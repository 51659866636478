<template>
  <div>
    <div class="mapRight">
      <div class="control-wrap">
        <div class="img-wrap">
          <img
            v-show="fullShow"
            src="/static/img/full_enter.png"
            @click="fullScreen"
            alt=""
            :class="{ 'img-sty': true }"
          />
          <img
            v-show="!fullShow"
            src="/static/img/full_exit.png"
            @click="fullScreen"
            alt=""
            :class="{ 'img-sty': true }"
          />
          <div class="line"></div>
          <img
            v-show="showEcharts"
            src="/static/img/icon_top_time.png"
            alt=""
            :class="{ 'img-sty': true }"
            @click="showEcharts = !showEcharts"
          />
          <img
            v-show="!showEcharts"
            src="/static/img/icon_top_time_grey.png"
            alt=""
            :class="{ 'img-sty': true }"
            @click="showEcharts = !showEcharts"
          />
          <div class="line"></div>
          <img
            v-show="showLocation"
            src="/static/img/icon_top_location.png"
            @click="showMarker"
            alt=""
            :class="{ 'img-sty': true }"
          />
          <img
            v-show="!showLocation"
            src="/static/img/icon_top_location_grey.png"
            @click="showMarker"
            alt=""
            :class="{ 'img-sty': true }"
          />
        </div>
      </div>
      <div v-if="showEcharts">
        <div class="content-wrap">
          <div class="title">
            <div></div>
            <CloseOutlined
              style="color: #fff"
              @click="showEcharts = !showEcharts"
            />
          </div>
        </div>
        <div class="table">
          <ud-pro-table
            ref="table"
            row-key="name"
            :datasource="dataList"
            :columns="columns"
            :needPage="false"
            :toolbar="false"
            :customRow="customRow"
            :scroll="{ y: 150 }"
          >
            <template #toolbar> </template>
          </ud-pro-table>
        </div>
        <div class="content-wrap">
          <div class="content">
            <Linear
              ref="lineData"
              :data="linearData"
              :option="1"
              val="number"
            ></Linear>
          </div>
        </div>
        <div class="content-wrap">
          <div class="content">
            <OverView ref="stateData" :data="overData" :val="1"></OverView>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { CloseOutlined } from '@ant-design/icons-vue'
import * as assetsInfoApi from '@/api/ams/assets'
import OverView from './echarts/overview.vue'
import Linear from './echarts/linear.vue'
export default {
  props: {
    warehouseList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  components: {
    CloseOutlined,
    OverView,
    Linear
  },
  data() {
    return {
      showEcharts: true,
      showLocation: true,
      fullShow: true,
      columns: [
        {
          width: 48,
          align: 'center',
          customRender: ({ index }) => index + 1
        },
        {
          title: '单位',
          align: 'left',
          dataIndex: 'name'
        },
        {
          width: 70,
          title: '数量',
          align: 'left',
          dataIndex: 'value'
        },
        {
          width: 80,
          title: '价值',
          align: 'center',
          dataIndex: 'total'
        }
      ],
      linearData: [],
      overData: [],
      customKey: 0,
      dataList: []
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.controlScroll()
      this.reload()
    })
  },
  watch: {
    warehouseList: {
      handler(newValue, oldValue) {
        this.reload()
      },
      deep: true
    }
  },
  created() {},
  methods: {
    initData(id) {
      const index = this.dataList.findIndex((item) => {
        return item.id === id
      })
      this.customKey = id
      this.getLinearData(id)
      this.getOverData(id)
      this.controlScroll(index)
    },
    // 全屏事件监听
    fullScreen() {
      this.fullShow = !this.fullShow
      this.$nextTick(() => {
        var tabsBarDom = document.querySelector('.ant-tabs-bar')
        var copyDom = document.querySelector('.ud-text-center')
        var adminHeaderDom = document.querySelector('.ud-admin-header')
        var udBodyDom = document.querySelector('.ud-body')
        var udAdminContentDom = document.querySelector('.ud-admin-content')
        var sidebarDom = document.querySelector('.ud-admin-sidebar')
        if (!this.fullShow) {
          if (tabsBarDom && copyDom && udBodyDom && udAdminContentDom) {
            tabsBarDom.style.display = 'none'
            copyDom.style.display = 'none'
            adminHeaderDom.style.height = '0'
            adminHeaderDom.style.overflow = 'hidden'
            udBodyDom.style.height = '100vh'
            udAdminContentDom.style.height = '100vh'
            if (sidebarDom) {
              sidebarDom.style.display = 'none'
            }
          }
        } else {
          if (tabsBarDom && copyDom && udBodyDom && udAdminContentDom) {
            tabsBarDom.style.display = 'block'
            copyDom.style.display = 'block'
            adminHeaderDom.style.height = '48px'
            udBodyDom.style.height = 'calc(100vh - 40px - 48px)'
            udAdminContentDom.style.height = 'calc(100vh - 40px - 48px)'
            if (sidebarDom) {
              sidebarDom.style.display = 'block'
            }
          }
        }
        this.$emit('resize')
      })
    },
    // 控制滚动条方法
    controlScroll(index) {
      if (this.$refs.table) {
        var scrollDom = this.$refs.table.$el.querySelector('.ant-table-body')
        const rowDom = this.$refs.table.$el.querySelectorAll('.ant-table-row')
        if (rowDom.length > 0) {
          let rowHeight = 0
          rowDom.forEach((item, itemIndex) => {
            if (itemIndex < index) {
              rowHeight += item.clientHeight
            }
          })
          scrollDom.scrollTop = rowHeight
        }
      }
    },
    // 是否展示地图点位
    showMarker() {
      this.showLocation = !this.showLocation
      this.$emit('showMarker', this.showLocation)
    },
    // 获取条形图数据
    getLinearData(id) {
      assetsInfoApi
        .categoryAssetsReport(id)
        .then((res) => {
          if (res.code === 0) {
            const data = res.data.map((item) => {
              return item.count
            })
            this.linearData = data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // 获取饼状图数据
    getOverData(id) {
      assetsInfoApi
        .stateAssetsReport(id)
        .then((res) => {
          if (res.code === 0) {
            res.data.forEach((item) => {
              item.value = item.count
            })
            this.overData = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
      // var arr = [
      //   [
      //     { value: 100, name: '空闲 17 (71%)', percent: '100%' },
      //     { value: 1, name: '在用 4 (17%)', percent: '0%' },
      //     { value: 1, name: '待审批 2 (8%)', percent: '0%' },
      //     { value: 1, name: '借用 1 (4%)', percent: '0%' }
      //   ],
      //   [
      //     { value: 1048, name: '空闲 27 (51%)', percent: '51%' },
      //     { value: 235, name: '在用 4 (27%)', percent: '17%' },
      //     { value: 280, name: '待审批 2 (2%)', percent: '8%' },
      //     { value: 284, name: '借用 1 (24%)', percent: '4%' }
      //   ],
      //   [
      //     { value: 3448, name: '空闲 17 (61%)', percent: '61%' },
      //     { value: 335, name: '在用 4 (37%)', percent: '37%' },
      //     { value: 380, name: '待审批 2 (38%)', percent: '38%' },
      //     { value: 384, name: '借用 1 (34%)', percent: '34%' }
      //   ]
      // ]
      // = arr[this.customKey] || [
      //   { value: 2048, name: '空闲 17 (71%)', percent: '71%' },
      //   { value: 735, name: '在用 4 (17%)', percent: '17%' },
      //   { value: 580, name: '待审批 2 (8%)', percent: '8%' },
      //   { value: 484, name: '借用 1 (4%)', percent: '4%' }
      // ]
    },
    // 表格行移入自定义事件
    customRow(record) {
      return {
        style: {
          backgroundColor: record.id === this.customKey ? '#e6f7ff' : ''
        },
        onMouseenter: (event) => {
          this.customKey = record.id
          this.getLinearData(record.id)
          this.getOverData(record.id)
        }
      }
    },
    reload() {
      this.dataList = this.warehouseList.filter((item) => {
        return item.show
      })
      if (this.dataList.length > 0) {
        this.customKey = this.dataList[0].id
        this.getLinearData(this.dataList[0].id)
        this.getOverData(this.dataList[0].id)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.mapRight {
  position: absolute;
  right: 40px;
  top: 50px;
  z-index: 1000;
  .control-wrap {
    display: flex;
    justify-content: flex-end;
    .img-wrap {
      width: 250px;
      background: #fff;
      padding: 10px;
      margin-bottom: 10px;
      border-radius: 10px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .img-sty {
        width: 25px;
        height: 25px;
      }
      .line {
        width: 1px;
        height: 15px;
        background-color: grey;
      }
    }
  }
  .content-wrap {
    background: #fff;
    .title {
      display: flex;
      justify-content: space-between;
      align-content: center;
      font-size: 18px;
      color: #fff;
      background-color: #37a2da;
      padding: 11px;
    }
  }
}
@media screen and (max-width: 1366px) {
  .mapRight {
    width: 370px;
  }
}
@media screen and (min-width: 1367px) {
  .mapRight {
    width: 420px;
  }
}
</style>
